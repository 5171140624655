<template>
  <van-tabs
    v-model="active"
    class="tab-page"
    color="#055C9D"
    title-active-color="#055C9D"
    title-inactive-color="#AAB3BA"
    line-width="75"
    swipeable
    @change="handleTabChange"
  >
    <order-list-tab
      v-for="(item, index) in tabList"
      :key="index"
      :id="item.value"
      :title="item.label"
      :name="item.value"
      :loading="loading"
      @onRefresh="onRefresh"
    />
  </van-tabs>
</template>

<script>
import OrderListTab from '@/components/V2/OrderList/OrderListTab';
import {ORDER_STATUS_DICT, ORDER_WAITING_START} from '@/constants';
import {mapActions, mapGetters, mapMutations} from "vuex";
import {getDocumentTitle} from "@/utils/taskData";
import {isNative} from "@/config";

export default {
  name: "orderList",
  components: {
    OrderListTab
  },
  data() {
    return {
      active: ORDER_WAITING_START,
      tabList: [],
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['taskInfo']),
  },
  async created() {
    console.log('orderList page created, the url is:', window.location.href)
    this.initTab()
    this.SET_TASK_ID(this.$route.query.taskId)
    this.active = this.$route.query.active || ''
    this.loading = true
    await this.getTaskInfo()
    this.loading = false
    document.title = getDocumentTitle(this.taskInfo)
    if (isNative) {
      window.nativeApp.showMoreBtn(true)
    }
  },
  beforeDestroy() {
    if (isNative) {
      window.nativeApp.showMoreBtn(false)
    }
  },
  methods: {
    ...mapMutations(['SET_TASK_ID']),
    ...mapActions(['getTaskInfo']),
    initTab() {
      const tabList = Object.entries(ORDER_STATUS_DICT).map(([value, label]) => {
        return {label, value}
      })
      this.tabList = tabList.concat({
        label: '全部',
        value: ''
      })
    },
    handleTabChange() {
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          active: this.active
        }
      })
    },
    async onRefresh() {
      this.loading = true
      await this.getTaskInfo()
      this.loading = false
    }
  },
}
</script>

<style lang="less" scoped>
.tab-page {
  background: #F9F9F9;
}
</style>
