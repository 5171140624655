<template>
  <div ref="listScroll" class="tab-content">
    <no-data v-if="!myLoading && orderList && orderList.length === 0"/>
    <van-collapse v-else v-model="activeName">
      <van-collapse-item
        v-for="(order, index) in orderList"
        :key="index"
        :name="index"
      >
        <div class="order-item vertical-center" slot="title">
          <img
            :class="{ 'is-active': activeName.includes(index) }"
            src="~@/assets/svg/icon-caret.svg"
            alt=""
          />
          <div class="title">订单号：{{ order.orderNum }}</div>
          <div v-if="order.articleNoList" class="num">
            {{ order.articleNoList && order.articleNoList.length }}/ {{ orderTotal(order) }}
          </div>
        </div>
        <div
          v-for="(item, index2) in order.articleNoList"
          :key="index2"
          class="sub-order space-between"
          @click="handleNavTo(item, order)"
        >
          <div class="content">
            <div class="name">
              {{ item.articleNo }} {{ item.articleNoDesc }}
            </div>
            <div class="desc">
              订单/完成/抽检数量：
              {{ item.applicationQuantity || '-' }}
              /{{ item.skipReason ? `未点数` : qualityNumber(item.completedQuantity) }}
              /{{ item.status === ORDER_WAITING_START ? '-' : item.samplingQuantity || '-' }}
            </div>
            <div class="defect">{{ defect(item) }}</div>
            <div v-for="(conclusion, index) in conclusionList(item.conclusionList)" :key="index" class="conclusion">
              <div :class="`result result-${conclusion.conclusion}`">
                {{ ALL_ARTICLE_CONCLUSION_DICT[conclusion.conclusion] }}
              </div>
              <div class="submit-detail">
                <span>{{ conclusion.submitTime | moment('YYYY-MM-DD HH:mm') }}</span>
                <!--<span>{{ ROLE_DICT[conclusion.role] }}</span>-->
<!--                <span>{{ conclusion.user }}提交</span>-->
              </div>
            </div>
          </div>
          <div class="icon-wrapper space-between vertical-center">
            <span :class="`status status-${item.status}`">
              {{ ARTICLE_NO_STATUS_DICT[item.status] }}
            </span>
            <img src="~@/assets/svg/icon-arrow-right.svg" alt=""/>
          </div>
        </div>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>

<script>
import {
  SAMPLING_LEVEL_DICT,
  ARTICLE_NO_STATUS_DICT,
  ALL_ARTICLE_CONCLUSION_DICT,
  SUPPLIER_CONCLUSION_DICT,
  ARTICLE_NO_WAITING_START,
  ARTICLE_NO_PROCESSING,
  ORDER_WAITING_START,
  ROLE_DICT
} from "@/constants";
import {mapGetters} from "vuex";
import NoData from "@/components/Common/NoData.vue";
import {cloneDeep} from "lodash";

export default {
  name: "OrderListTabContent",
  components: {NoData},
  props: {
    name: {
      type: String,
      default: "",
    },
    orderList: {
      type: Array,
      default() {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      SAMPLING_LEVEL_DICT,
      ARTICLE_NO_STATUS_DICT,
      ALL_ARTICLE_CONCLUSION_DICT,
      ROLE_DICT,
      SUPPLIER_CONCLUSION_DICT,
      activeName: [],
      myLoading: false,
      ORDER_WAITING_START
    };
  },
  computed: {
    ...mapGetters(['taskInfo']),
    conclusionList() {
      return function (conclusionList) {
        const dataList = cloneDeep(conclusionList || [])
        const  result = dataList.reverse()
        if (Array.isArray(result) && result.length > 0) {
          return [result[0]]
        } else {
          return []
        }
      }
    },
    defect() {
      return function (item) {
        if (item.inspectionList?.defectList?.length) {
          const defectList = item.inspectionList.defectList
          const cri = defectList.reduce((prev, {cri}) => {
            return prev + parseInt(cri)
          }, 0)
          const maj = defectList.reduce((prev, {maj}) => {
            return prev + parseInt(maj)
          }, 0)
          const min = defectList.reduce((prev, {min}) => {
            return prev + parseInt(min)
          }, 0)
          return `缺陷数量：CRI ${cri}，MAJ ${maj}, MIN ${min}`
        }
        return ''
      }
    },
    orderTotal() {
      return function (order) {
        const orderList = this.taskInfo.orderList || []
        const item = orderList.find(item => item.orderNum === order.orderNum)
        return item?.articleNoList?.length || 0
      }
    },
    qualityNumber(){
      return function (val) {
        if(!val && val !== 0) {
          return '-'
        } else {
          return val
        }
      }
    },
  },
  watch: {
    loading: {
      immediate: true,
      handler: function (val) {
        this.myLoading = val
      }
    },
    orderList: {
      immediate: true,
      handler: function () {
        this.initActiveName()
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      let listScroll = this.$refs.listScroll;
      if (listScroll) {
        listScroll.addEventListener('scroll', () => {
          this.$emit('scroll', listScroll.scrollTop)
        });
      }
    })
  },
  methods: {
    initActiveName(){
      this.activeName = this.orderList.map((item, index) => index)
      console.log('this.activeName:', this.activeName)
    },
    handleNavTo(item, order) {
      let path = '/result'
      if (item.status === ARTICLE_NO_WAITING_START) {
        path = '/countSample'
      } else if (item.status === ARTICLE_NO_PROCESSING) {
        path = '/check'
      }
      const query = {
        ...this.$route.query,
        orderId: order.id,
        articleId: item.id,
      }
      delete query.from
      this.$router.push({path, query});
    },
  },
};
</script>

<style lang="less" scoped>
.tab-content::v-deep {
  padding-top: 16px;
  padding-bottom: 16px;
  box-sizing: border-box;
  height: calc(100vh - 44px);

  .van-collapse-item__title {
    padding: 12px 8px;
  }

  .order-item {
    justify-content: flex-start;
    line-height: 22px;

    img {
      width: 16px;
      height: 16px;

      &.is-active {
        transform: rotate(90deg);
      }
    }

    .num {
      flex: 1 1;
      text-align: right;
    }
  }

  .sub-order {
    padding: 4px 8px 4px 40px;
    border-bottom: 1px solid #dfe4eb;

    .content {
      width: calc(100% - 80px);

      .name {
        font-size: 14px;
        color: #4B4E51;
      }

      .desc {
        font-size: 12px;
        color: #797C80;
      }

      .defect {
        font-size: 12px;
        color: #AAB3BA;
      }

      .conclusion {
        font-size: 12px;
        display: flex;
        align-items: center;

        .result {
          width: 38px;
          height: 16px;
          line-height: 16px;
          text-align: center;
          margin-right: 5px;
          color: #35BF89;
          background: #35bf8914;

          &.result-2, &.result-N {
            color: #F94837;
            background: #F9483708;
          }

          &.result-3 {
            color: #FFBD2E;
            background: #FFBD2E08;
          }

          &.result-4 {
            color: #108AEE;
            background: #108AEE08;
          }
        }

        .submit-detail {
          width: calc(100% - 40px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }


    .icon-wrapper {
      padding-right: 8px;
      flex-shrink: 0;

      .status {
        font-size: 14px;

        &.status-01 {
          color: #108AEE;
        }

        &.status-02 {
          color: #055C9D;
        }

        &.status-03 {
          color: #FFBD2E;
        }

        &.status-04 {
          color: #35BF89;
        }
      }

      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  .van-cell {
    border-bottom: 1px solid #dfe4eb;
  }

  .van-collapse-item__content {
    padding: 0;
  }

  .van-cell::after,
  .van-cell__right-icon {
    display: none;
  }
}
</style>
