<template>
  <van-tab v-bind="$attrs">
    <template #title>
      {{ $attrs.title }}
      <span v-if="total !== 0" class="num">{{ totalFormatData }}</span>
    </template>
    <van-pull-refresh v-model="myLoading" :disabled="refreshDisabled" @refresh="onRefresh">
      <order-list-tab-content
        ref="orderListTabContent"
        :order-list="orderList"
        :loading="loading"
        @scroll="onScroll"
      />
    </van-pull-refresh>
  </van-tab>
</template>

<script>
import OrderListTabContent from '@/components/V2/OrderList/OrderListTabContent';
import {getAction} from '@/api/manage';
import {InbounceMixin} from '@/mixins/InbounceMixin';
import {mapActions, mapGetters} from "vuex";
import {cloneDeep} from "lodash";

export default {
  name: "OrderListTab",
  mixins: [InbounceMixin],
  components: {
    OrderListTabContent
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      finished: false,
      total: 0,
      queryParams: {
        pageNo: 0,
        pageSize: 10,
      },
      orderList: [],
      statusArticleNoList: [],
      scrollTop: 0,
      myLoading: false
    }
  },
  computed: {
    ...mapGetters(['taskInfo']),
    totalFormatData() {
      let value = this.total
      switch (value) {
        case value <= 0:
          return ''
        case value > 99:
          return '99+'
        default:
          return value
      }
    },
    refreshDisabled() {
      return this.scrollTop > 0
    },
  },
  watch: {
    loading: {
      immediate: true,
      handler: function (val) {
        this.myLoading = val
      }
    },
    taskInfo: function () {
      this.getOrderList()
    }
  },
  mounted() {
    this.getOrderList()
  },
  methods: {
    ...mapActions(['getTaskInfo', 'saveTaskInfo']),
    onScroll(val) {
      this.scrollTop = val
    },
    onRefresh() {
      this.$emit('onRefresh')
    },
    async getOrderList() {
      const orderList = cloneDeep(this.taskInfo?.orderList || [])
      this.orderList = []
      this.statusArticleNoList = []
      orderList.forEach(order => {
        if (this.$attrs.name) {
          const index = order.articleNoList.findIndex(item => item.status === this.$attrs.name)
          if (~index) {
            const articleNoList = order.articleNoList.map(item => item).filter(item => item.status === this.$attrs.name)
            this.orderList.push({
              ...order,
              articleNoList,
            })
            this.statusArticleNoList = this.statusArticleNoList.concat(articleNoList)
          }
        } else {
          this.orderList.push(order)
          this.statusArticleNoList = this.statusArticleNoList.concat(order.articleNoList || [])
        }
      })
      this.total = this.statusArticleNoList.length
    },
  }
}
</script>

<style lang="less" scoped>
.num {
  font-size: 10px;
  color: #F94837;
  top: 4px;
  position: absolute;
}

.loading {
  margin-top: 70px;
  text-align: center;
}
</style>
